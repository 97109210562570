<template>
  <v-container fluid style="height: 100%; display: flex; flex-direction: column">
    <page-title
      title="Sub Contractor"
      subtitle="Browse and Manage your Sub Contractor!"
      :badge="this.total"
    />
    <v-row class="mt-0 mb-1 justify-space-between">
      <v-col>
        <v-btn color="info" @click="add" v-if="$has(perms.SubContractors.Create)"
          ><i class="fal fa-plus mr-2"></i>Add Sub Contractor</v-btn
        >
      </v-col>
      <v-col cols="12" sm="12" md="9" lg="8">
        <div class="d-flex flex-row align-center justify-end flex-wrap" style="gap: 0.5rem">
          <filter-manager
            ref="filterManager"
            v-model="selectedFilters"
            :options.sync="options"
          ></filter-manager>
          <v-sheet height="28" width="1" color="blue-grey lighten-4" class="mx-1"></v-sheet>
          <bulk-import-companies
            :type="companyType.SubContractor.value"
            @imported="getDataDebounced()"
          ></bulk-import-companies>
          <columns-visibility-control
            :defaults="defaultHeaders"
            :withColumnsOrder="true"
            v-model="headers"
            :storageKey="storageKey"
          />
          <v-text-field
            v-model="options.search"
            label="Search"
            ref="mainSearch"
            class="table-search-field"
            @focus="mainSearchInFocus = true"
            @blur="mainSearchInFocus = false"
            :style="{
              'max-width':
                mainSearchInFocus || (options.search != '' && options.search != null)
                  ? '200px'
                  : '110px',
            }"
            dense
            solo
            clearable
            hide-details=""
            prepend-inner-icon="far fa-search"
            :loading="loadingStates.table"
          >
          </v-text-field>
          <refresh :loading="loadingStates.table" @refresh="getDataDebounced()"></refresh>
        </div>
      </v-col>
    </v-row>
    <v-data-table
      style="
        overflow: auto;
        overflow: overlay;
        height: 100%;
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
      "
      dense
      :headers="tableHeader"
      :items="entities"
      :options.sync="options"
      :server-items-length="total"
      :items-per-page="options.itemsPerPage"
      :loading="loadingStates.table"
      @click:row="rowClicked"
      class="elevation-2 companies-table table-sticky-header-exclude-footer"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'far fa-arrow-to-left',
        lastIcon: 'far fa-arrow-to-right',
        prevIcon: 'far fa-angle-left',
        nextIcon: 'far fa-angle-right',
        itemsPerPageOptions: [15, 30, 50, 100],
      }"
    >
      <template v-slot:progress>
        <v-progress-linear color="blue" absolute indeterminate></v-progress-linear>
      </template>

      <template v-slot:[`item.name`]="{ item }">
        <div class="d-flex align-center" style="gap: 0.5rem">
          <v-tooltip right z-index="999" nudge-right="-4px">
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <div class="company-logo">
                  <div
                    style="height: 100%; width: 100%"
                    v-viewer
                    @click.stop
                    v-if="item.logoUrl != null && item.logoUrl != ''"
                  >
                    <img :key="item.id + '_img'" :src="item.logoUrl" height="100%" width="100%" />
                  </div>
                  <div style="height: 100%; width: 100%" v-viewer @click.stop v-else>
                    <img
                      :key="item.id + '_img'"
                      src="/img/DNA_Symbol.png"
                      height="100%"
                      width="100%"
                    />
                  </div>
                </div>
              </div>
            </template>
            <span>
              <v-img
                v-if="item.logoUrl != null && item.logoUrl != ''"
                :src="item.logoUrl"
                height="250px"
                width="250px"
                contain
              ></v-img>
              <v-img v-else src="/img/DNA_Symbol.png" height="250px" width="250px" contain></v-img>
            </span>
          </v-tooltip>
          <h3 class="ma-0">{{ item.name }}</h3>
        </div>
      </template>

      <template v-slot:[`item.createdBy`]="{ item }">
        <user-avatar :user="item.createdBy" icon></user-avatar>
      </template>

      <template v-slot:[`item.createDate`]="{ item }">
        <dater :date="item.createDate" dateonly></dater>
      </template>

      <template v-slot:[`item.updatedBy`]="{ item }">
        <user-avatar :user="item.updatedBy" icon></user-avatar>
      </template>

      <template v-slot:[`item.updateDate`]="{ item }">
        <dater :date="item.updateDate" dateonly></dater>
      </template>

      <template v-slot:[`item.contactsCount`]="{ item }">
        <v-btn outlined color="secondary" small @click.stop="showContacts(item)" rounded>
          <i class="fad fa-user-group"></i>
          <span class="ml-2 fs-12px font-weight-bold">{{ item.contactsCount }}</span>
        </v-btn>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-menu dense offset-x right>
          <template v-slot:activator="{ attrs, on }">
            <v-btn icon elevation="0" v-bind="attrs" v-on="on">
              <i class="far fa-ellipsis-v" style="font-size: 16px"></i>
            </v-btn>
          </template>
          <v-list class="more-options-menu">
            <v-list-item @click="showContacts(item)" v-if="$has(perms.CompanyContacts.View)">
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small>fal fa-address-book</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Contacts</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-divider class="my-1"></v-divider>

            <v-list-item @click.stop="update(item.id)" v-if="$has(perms.SubContractors.Update)">
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small>fal fa-pen</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Update</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item @click="convertToClient(item)" v-if="$has(perms.SubContractors.Update)">
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small>fal fa-user-tie</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Convert To Client</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-divider class="my-1"></v-divider>

            <v-list-item @click.stop="del(item)" v-if="$has(perms.SubContractors.Delete)">
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small>fal fa-trash-alt red--text</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="red--text">Delete</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>

      <template v-slot:no-data>
        <img width="500" src="/img/art/fogg-no-connection-2.png" />
        <p class="font-weight-bold">No Data Available!</p>
      </template>
      <template v-slot:loading>
        <video width="250" muted loop autoplay style="padding: 0.5rem">
          <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
        </video>
        <p class="font-weight-bold">Searching the Cosmos...</p>
      </template>
    </v-data-table>

    <edit-subContractor
      @save="onSubContractorSaved"
      @close="onSubContractorClosed"
      @delete="onSubContractorDeleted"
      ref="editSubContractor"
    ></edit-subContractor>

    <manage-contacts ref="manageContacts"></manage-contacts>
  </v-container>
</template>

<script>
import enums from "../../../../plugins/enums";
import perms from "../../../../plugins/permissions";
import ApiService from "../services/subContractors-service.js";
import EditSubContractor from "../components/EditSubContractor.vue";
import subContractorHeader from "../config/tables/subContractors.header";
import ManageContacts from "../../Shared/components/ManageContacts.vue";
import BulkImportCompanies from "../../Shared/components/BulkImportCompanies.vue";
import companiesApi from "../../Shared/services/companies-service";
import FilterManager from "../../../Shared/components/FilterManager/FilterManager.vue";
import { UserFilterSettings } from "../../../Shared/components/FilterManager/FilterSettings";

export default {
  components: {
    EditSubContractor,
    ManageContacts,
    BulkImportCompanies,
    FilterManager,
  },
  data() {
    return {
      companyType: enums.CompanyType,
      perms: perms,
      storageKey: "SubContractor",
      paramId: null,
      selectedFilters: [],
      entities: [],
      selected: {},
      total: 0,
      search: "",
      valid: false,
      subContractorTimerId: null,
      mainSearchInFocus: false,
      options: {
        id: null,
        search: null,
        sortBy: ["name"],
        sortDesc: [false],
        createdByIds: [],
        updatedByIds: [],
      },
      loadingStates: {
        table: false,
      },
      headers: subContractorHeader,
      defaultHeaders: [],
    };
  },
  computed: {
    tableHeader() {
      return this.headers.filter((elm) => !elm.hidden);
    },
  },
  created() {
    this.defaultHeaders = this.headers;
    if (this.storageKey) {
      this.options.itemsPerPage = +this.$getFromLocal(
        `${this.storageKey}-itemsPerPage`,
        false,
        this.options.itemsPerPage || 15
      );
    }
  },
  mounted() {
    this.initFilterManagerSettings();
    document.querySelector("main.v-main").classList.add("sticky-main-fix");
    //this.$refs.mainSearch.focus();
    this.checkForSingleSubContractorRoute(this.$route);
  },
  beforeDestroy() {
    document.querySelector("main.v-main").classList.remove("sticky-main-fix");
  },
  methods: {
    initFilterManagerSettings() {
      var createdByFilterSettings = new UserFilterSettings();
      createdByFilterSettings.title = "Created By";
      createdByFilterSettings.model = "createdByIds";
      createdByFilterSettings.ref = "createdByFilter";

      var updatedByFilterSettings = new UserFilterSettings();
      updatedByFilterSettings.title = "Updated By";
      updatedByFilterSettings.model = "updatedByIds";
      updatedByFilterSettings.ref = "updatedByFilter";

      this.selectedFilters = [createdByFilterSettings, updatedByFilterSettings];
    },
    convertToClient(company) {
      this.$dialog
        .warning({
          title: `Convert to Client?`,
          text: `Are you sure you want to convert '<u>${company.name}</u>' to be a client?`,
          color: "warning",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Confirm",
              color: "warning",
              handle: () => {
                return companiesApi
                  .convertToClient(company.id)
                  .then((resp) => {
                    this.$dialog.notify.success("Converted to client successfully!", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  })
                  .catch((err) => {
                    this.$handleError(err);
                  });
              },
            },
          },
        })
        .then((res) => {});
    },
    showContacts(company) {
      this.$refs.manageContacts.open(company.id, false);
    },
    getData() {
      this.$backToTop(0, document.querySelector(".companies-table .v-data-table__wrapper"));
      this.loadingStates.table = true;
      ApiService.query(this.$clean(this.options, true))
        .then((resp) => {
          this.entities = resp.data.items;
          this.total = resp.data.total;
          this.loadingStates.table = false;
        })
        .catch((err) => {
          this.loadingStates.table = false;
          this.$handleError(err);
        });
    },
    add() {
      this.selected = {};
      this.$refs.editSubContractor.open(null);
    },
    update(id) {
      this.selected = { id: id };
      setTimeout(() => {
        const path = `/sub-contractors/${id}`;
        if (this.$route.path !== path) this.$router.push(path);
        this.$refs.editSubContractor.open(id, true);
      });
    },
    view(id) {
      setTimeout(() => {
        const path = `/sub-contractors/${id}`;
        if (this.$route.path !== path) this.$router.push(path);
        this.$refs.editSubContractor.open(id);
      });
    },
    rowClicked(row) {
      this.view(row.id);
    },
    del(company) {
      this.$dialog
        .warning({
          text: `Are you sure you want to delete this sub contractor: <b>${company.name}</b>?`,
          title: `Delete Sub Contractor?`,
          color: "error",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Confirm",
              color: "error",
              handle: () => {
                return ApiService.delete(company.id)
                  .then((resp) => {
                    this.onSubContractorDeleted(company.id);
                    this.$dialog.notify.success("Sub Contractor deleted successfully!", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  })
                  .catch((err) => {
                    this.$handleError(err);
                  });
              },
            },
          },
        })
        .then((res) => {});
    },
    onSubContractorDeleted(id) {
      const index = this.entities.indexOf(this.entities.filter((e) => e.id == id)[0]);
      if (index > -1) {
        this.entities.splice(index, 1);
      }
    },
    onSubContractorClosed() {
      const path = `/sub-contractors`;
      if (this.$route.path !== path) this.$router.push(path);
    },
    onSubContractorSaved(subContractorData, isNewSubContractor) {
      this.$log("onSubContractorSaved", subContractorData, isNewSubContractor);
      if (isNewSubContractor) {
        this.addToArr(this.entities, subContractorData);
        this.total++;
      } else {
        this.updateArr(this.entities, subContractorData);
      }
    },
    getDataDebounced() {
      if (this.subContractorTimerId == null) {
        this.subContractorTimerId = -1;
        this.getData();
        return;
      }
      // cancel pending call
      clearTimeout(this.subContractorTimerId);

      // delay new call 400ms
      this.subContractorTimerId = setTimeout(() => {
        this.getData();
      }, 400);
    },
    checkForSingleSubContractorRoute(route) {
      if (route.params.id) {
        this.paramId = Number.parseInt(route.params.id);
        this.view(this.paramId);
      } else this.paramId = null;
    },
  },
  watch: {
    options: {
      handler(val) {
        this.$setToLocal(`${this.storageKey}-itemsPerPage`, val.itemsPerPage);
        this.getDataDebounced();
      },
      deep: true,
    },

    $route: {
      handler(newRoute) {
        this.checkForSingleSubContractorRoute(newRoute);
      },
      deep: true,
    },
  },
};
</script>
